import React from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'

import Hero from 'components/hero'
import Layout from 'components/layout'
import ProfilePreviewImages from 'components/portfolio/preview-images'
import TextContainer from 'components/common/text-container'
import { Heading, SubHeading } from 'components/common/typography'

const PortfolioPage = ({ data }) => {
  return (
    <Layout>
      <Helmet
        title='Wedding Calligraphy, Live Events, In-Store Art | Portfolio'
        meta={[
          {
            name: 'description',
            content:
              'Pinya Letters carries an extensive portfolio of work demonstrating unique craft in wedding calligraphy, in-store art, and live-events.',
          },
        ]}
      />
      <Hero file={data.heroImage} />
      <TextContainer>
        <Heading>Portfolio</Heading>
        <p>
          My portfolio is a collection of work that demonstrates my unique craft in multiple areas
          of calligraphy.
        </p>
        <p>
          For quotes and pricing inquiries please contact me <Link to='/contact'>here</Link>.
        </p>
        {data.portfolioCategories.edges
          .map(({ node }) => node)
          .filter(({ images }) => images && images.length > 0)
          .map(({ label, slug, images }) => (
            <div key={slug}>
              <Link to={`/portfolio/${slug}`}>
                <SubHeading>{label}</SubHeading>
              </Link>
              <ProfilePreviewImages category={slug} images={images.slice(0, 4)} />
            </div>
          ))}
      </TextContainer>
    </Layout>
  )
}

export const query = graphql`
  query PortfolioPageQuery {
    portfolioCategories: allContentfulPortfolioCategory(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          label

          images {
            ...ProfilePreviewImagesContentfulAsset
          }
        }
      }
    }

    heroImage: file(name: { eq: "hero11" }) {
      ...HeroFile
    }
  }
`

export default PortfolioPage
