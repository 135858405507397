import React from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'

import { transparentGray, moreTransparentGray } from 'components/theme/colours'
import { secondaryFont } from 'components/theme/typography'
import PortfolioPreviewImage from 'components/portfolio/preview-image'

const Grid = styled.div`
  display: grid;
  grid-gap: 16px;
  width: 100%;
  margin-top: 16px;
  grid-template-columns: repeat(2, 1fr);
`

const ViewMoreOverlay = styled.div`
  transition: background-color 0.2s ease-in;
  background-color: ${transparentGray};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  font-size: 16px;
  justify-content: center;
  align-items: center;
  font-family: ${secondaryFont};
  text-transform: uppercase;
  color: white;
  cursor: pointer;

  &:hover {
    background-color: ${moreTransparentGray};
  }
`

const ImageContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-right: 8px;
  height: 100%;
  width: 100%;
`

export default function ProfilePreviewImages({ images, category }) {
  return (
    <Grid>
      {images.map((asset, index) => (
        <ImageContainer key={asset.id}>
          <PortfolioPreviewImage asset={asset} />
          {index === images.length - 1 && (
            <Link to={`/portfolio/${category}`}>
              <ViewMoreOverlay>See More</ViewMoreOverlay>
            </Link>
          )}
        </ImageContainer>
      ))}
    </Grid>
  )
}

export const fragment = graphql`
  fragment ProfilePreviewImagesContentfulAsset on ContentfulAsset {
    id

    ...ProfilePreviewImageContentfulAsset
  }
`
